<template>
    <div class="releases-container">
        <div class="row-container">
            <Card class="releases-card base-card">
                <template #title>
                    <div class="table-header wwd-row wwd-sb">
                        <span>Releases</span>
                    </div>
                </template>
                <template #content>
                        <Fieldset class="base-fieldset" legend="3.2.6" :toggleable="true" :collapsed="false">
                                <h2> 26.09.2024 </h2>
                                <p> • The approach to storing sequences has changed. Now the sequences belong to the scenario. This means that the list of sequences is separate for each scenario </p>
                                <p> • Added setup sequence functionality. For each scenario in the manage scenario tab, it is possible to select the scenario that will be attached to the setup sequence button. This allows you to predefine the initial settings for each simulation </p>
                                <p> • Changed the way signal lists are selected for sequencer and editor mode. Now the signal list is assigned to the scenario, so you don't have to select the signal list. It is necessary to assign the signal list at the time of creating the scenario or in the manage scenario tab </p>
                                <p> • Added a preference tab in which it is possible to set advanced mode, which allows manipulation of scenarios and advanced options</p>
                        </Fieldset>
                        <Fieldset class="base-fieldset" legend="3.2.5" :toggleable="true" :collapsed="false">
                                <h2> 12.09.2024 </h2>
                                <p> • Fixed issue with automatic scenario selection </p>
                                <p> • Adjust view in easYgen panel to display signals which are not DI/DO</p>
                                <p> • Minor UI/UX improvements </p>
                                
                        </Fieldset>
                        <Fieldset class="base-fieldset" legend="3.2.4" :toggleable="true" :collapsed="false">
                                <h2> 26.08.2024</h2>
                                <p> • Adjusted colors of add step and add comment sequencer buttons</p>
                                <p> • Fixed issue with crashing simulation when step time was less than 0.250</p>
                                <p> • Added disable scroll checkbox in sequencer preview </p>
                                <p> • Fixed issue with multiple sequence running in the same time - now after restarting model active sequence is restarted </p>
                                <p> • Added confirmation button before closing sequence </p>
                        </Fieldset>
                        <Fieldset class="base-fieldset" legend="3.2.3" :toggleable="true" :collapsed="false">
                                <h2> 14.08.2024</h2>
                                <p> • Added privacy policy, terms & conditions and cookies informations</p>
                                <p> • Added cookies preferences support</p>
                        </Fieldset>
                        <Fieldset class="base-fieldset" legend="3.2.2" :toggleable="true" :collapsed="false">
                                <h2> 12.08.2024</h2>
                                <p> • Current step is now highlihted in sequencer preview </p>
                                <p> • Sequencer preview will also display comments </p>
                                <p> • Added possibility to restart sequence in sequencer preview </p>
                                <p> • Fixed issue with first step in sequencer when time was greater than 0 </p>
                                <p> • Sequencer preview is now scrolling automatically to current step </p>
                                <p> • In sequencer panel now it's possible to add steps and comments in between</p>
                                <p> • UI update of sequencer preview</p>
                        </Fieldset>
                        <Fieldset class="base-fieldset" legend="3.2.1" :toggleable="true" :collapsed="false">
                                <h2> 31.07.2024</h2>
                                <p> • Fixed wrong names in assets tab </p>
                                <p> • Removed possibility to select negative time in sequencer steps </p>
                                <p> • Fixed an issue that did not allow to see the entire name of a scenario when creating or selecting it in the sequencer. </p>
                                <p> • The list of signals in sequencer and edit mode will be selected automatically based on the scenario tag (except scenarios with 60Hz mode) </p>
                                <p> • The list of signals in the sequencer is now changed based on the action, now only signals that can be changed for a given action are shown. </p>
                        </Fieldset>
                        <Fieldset class="base-fieldset" legend="3.2.0" :toggleable="true" :collapsed="false">
                                <h2> 24.07.2024</h2>
                                <p> • Changed SimuBox Control card - open remote desktop button is more transparent, added coppy PIN button, UI update </p>
                                <p> • Added documantation link next to start model buttons and documentation for active model in home view </p>
                                <p> • Settings view is now main view after signing in </p>
                                <p> • Default scenarios now are auto-selected after starting model </p>
                                <p> • Off LED image change from red color to outlined </p>
                                <p> • Digital inputs now do not require observer mode to be turned off, and the ability to add to favorites from within the simulation has been removed. </p>
                            
                        </Fieldset>
                    <Fieldset class="base-fieldset" legend="3.1.6" :toggleable="true" :collapsed="false">
                            <h2> 17.07.2024</h2>
                            <p> • Improved responsive web design </p>
                            <p> • Fixed bugs related to sequencer - comments issue, timing issue, auto-close of dialog </p>
                            <p> • Fixed bugs related to edit mode - diodes issue, text labels issue </p>
                            <p> • Improved auto-reconnect functionality </p>
                            <p> • Minor UI/UX improvements </p>
                           
                        </Fieldset>
                        <Fieldset class="base-fieldset" legend="3.1.5" :toggleable="true" :collapsed="false">
                            <h2> 26.06.2024</h2>
                            <p> • Fixed sequencer bug which was not allowing to evalute step wchich had same time as comment. </p>
                            <p> • Added auto-recovery feature to sequencer - now sequencer will try to continue job even if disconnection was between.</p>
                            <p> • Changed way where comments are displayed when running sequence.</p>
                            <p> • Added help shortcut </p>
                            <p> • Minor UI/UX bug fixes </p>
                        </Fieldset>
                        <Fieldset class="base-fieldset" legend="3.1.4" :toggleable="true" :collapsed="false">
                            <h2> 09.05.2024</h2>
                            <p> • Added auto-reconnect functionality. </p>
                            <p> • Added information on recieving server information about simubox.</p>
                            <p> • Bug fix of incorrect simubox information on poor internet connection/disconnection.</p>
                            <p> • Minor UI/UX bug fixes </p>
                        </Fieldset>
                        <Fieldset class="base-fieldset" legend="3.1.3" :toggleable="true" :collapsed="false">
                            <h2> 17.04.2024</h2>
                            <p> • Added file restriction information on upload/download </p>
                            <p> • Added information about longer starting time </p>
                            <p> • Minor UI/UX bug fixes </p>
                        </Fieldset>
                        <Fieldset class="base-fieldset" legend="3.1.2" :toggleable="true" :collapsed="false">
                            <h2> 29.03.2024</h2>
                            <p> • FAQ sections removed until completed </p>
                            <p> • Improve UX by adding expanded simubox information </p>
                            <p> • Moved account settings from settings to a separate tab. </p>
                            <p> • The connect to simulation card is now only available when simubox is running. </p>          
                            <p> • Refresh ratio can only be changed when the model is running. </p>    
                            <p> • Small UI fixes   </p>    
                        </Fieldset>
                        <Fieldset class="base-fieldset" legend="3.1.1" :toggleable="true" :collapsed="false">
                            <h2> 14.03.2024</h2>
                            <p> • Hotfix of token refresh and backend address change</p>
                        </Fieldset>
                        <Fieldset class="base-fieldset" legend="3.1.0" :toggleable="true" :collapsed="false">
                            <h2> 07.03.2024</h2>
                            <p> • The first login approach has been changed. Now for the first login, it is necessary in the First Login section to enter your email address, to which a link will come that allows you to set a password. </p>
                            <p> • The SRA connection to the Simuboxes has been secured. Now a unique PIN is generated on each startup to log in to the remote simubox desktop.</p>
                            <p> • The dialog forcing a password change has been disabled. </p>
                        </Fieldset>
                        <Fieldset class="base-fieldset" legend="3.0.3" :toggleable="true" :collapsed="false">
                            <h2> 21.02.2024</h2>
                            <p> • Changed location of scenario name </p>
                            <p> • Added restart button for model control </p>
                            <p> • Icon for SRA is hidden till link appear </p>
                        </Fieldset>
                        <Fieldset class="base-fieldset" legend="3.0.2" :toggleable="true" :collapsed="false">
                            <h2> 20.02.2024</h2>
                            <p> • Adjusting application to DDNS remove </p>
                            <p> • Disabled option to input IP address and port of simulation (it's done automatically) </p>
                            <p> • Small UI/UX improvements </p>
                        </Fieldset>
                        <Fieldset class="base-fieldset" legend="3.0.1" :toggleable="true" :collapsed="false">
                            <h2> 16.02.2024</h2>
                            <p> • Improved performance of application by changing polling strategy  </p>
                        </Fieldset>
                        <Fieldset class="base-fieldset" legend="3.0.0" :toggleable="true" :collapsed="false">
                            <h2> 14.02.2024</h2>
                            <p> • Moved Cloud Simubox infrastructure from AWS to Skytap.  </p>
                            <p> • Added capability to start and stop the Simubox  </p>
                            <p> • Added automatic shutdown of Simubox when session time is up. Sessions can be extended any number of times.  </p>
                            <p> • The approach for connecting to simulated devices using Toolkit/VNC has been changed. Replaced SimVPN application -> Added possibility to connect to RDP of Cloud Simubox via browser. </p>
                            <p> • The Downloads tab has been removed -> From now on, it will no longer be needed. All the files you need to operate the simulation (Toolkit, VNC Client, Simulation Packages etc.) are located on Cloud Simubox, which you can access from now on using a browser. </p>
                            <p> • Added the ability to upload simulation-related files to Cloud Simubox. </p>
                            <p> • Added the ability to download simulation-related files from Cloud Simubox. </p>
                            <p> • Added loaders and introduced minor UI/UX fixes</p>
                        </Fieldset>
                        <Fieldset class="base-fieldset" legend="2.7.0" :toggleable="true" :collapsed="false">
                            <h2> 24.01.2024</h2>
                            <p> • WorkingPC packages update  </p>
                            <p> • Settings minor UI improvements  </p>
                            <p> • Fixed bug related to copying scenarios  </p>
                            <p> • Fixed bug related to automatically setting IP Address  </p>
                            <p> • SimVPN 1.3 release </p>
                            <p> • Added file validation for scenarios and signal lists </p>

                        </Fieldset>
                        <Fieldset class="base-fieldset" legend="2.6.0" :toggleable="true" :collapsed="false">
                            <h2> 04.12.2023</h2>
                            <p> • New login page  </p>
                            <p> • Footbar visual fixes  </p>
                            <p> • Sequencer now checks if signals in steps are in selected signal list  </p>
                        </Fieldset>
                        <Fieldset class="base-fieldset" legend="2.5.1" :toggleable="true" :collapsed="false">
                            <h2> 29.11.2023</h2>
                            <p> • Changed the way comments are added in the sequencer.  </p>
                            <p> • Sequencer checks before running to make sure it doesn't fall into an infinite loop. </p>
                            <p> • Sequencer visual fixes.  </p>
                            <p> • OpenVPN Toolkit renamed to SimVPN. Fixed problem when program was installed not on main disk.  </p>
                        </Fieldset>
                        <Fieldset class="base-fieldset" legend="2.5.0" :toggleable="true" :collapsed="false">
                            <h2> 16.11.2023</h2>
                            <p> • A sequencer has been added, allowing you to define actions that will happen at specific intervals in the simulation.  </p>
                            <p> • Added the possibility to save sequences, the prepared sequence can be run repeatedly.  </p>
                            <p> • Added the possibility to generate ramps for signals in the sequencer. This allows you to easily generate N steps by indicating what the value and time difference should be for the N+1 step.  </p>
                            <p> • Added a preview of the execution of the sequence after it has been run. The preview can be hidden and displayed at any time.  </p>
                            <p> • Added two side buttons in the main view of the application, they launch the monitor and sequencer preview.  </p>

                        </Fieldset>
                        <Fieldset class="base-fieldset" legend="2.4.4" :toggleable="true" :collapsed="false">
                            <h2> 02.10.2023</h2>
                            <p> • Added OpenVPN-Toolkit V2 to download section, and removed old one  </p>

                        </Fieldset>

                        <Fieldset class="base-fieldset" legend="2.4.3" :toggleable="true" :collapsed="false">
                            <h2> 28.09.2023 </h2>
                            <p> • Fixed a bug that caused units to be swapped when a parameter was removed from a block  </p>
                            <p> • Fixed a visual bug in releases view </p>
                            <p> • Added arrows to edit items to change the order in which parameters are displayed </p>
                            <p> • Fixed a bug that allowed blocks to be dropped on other blocks </p>
                            <p> • Fixed a bug that made it difficult to move the "Line" element </p>
                            <p> • Moved create scenario function from Home -> Manage simulation to Settings -> HMI Control </p>
                            <p> • Minor UI/UX improvments </p>

                        </Fieldset>

                        <Fieldset class="base-fieldset" legend="2.4.2" :toggleable="true" :collapsed="false">
                            <h2> 02.08.2023 </h2>
                            <p> • A downloads tab has been introduced. Through this tab you can download tools for interacting with the simulation, certificates for operating the tools and simulation packages. </p>
                            <p> • A FAQ section has been added where frequently asked questions will appear. </p>
                            <p> • Fixed a bug that appeared after going to the home tab without a selected scenario. Now a window appears that tells you to select a scenario and redirects you to the settings tab. </p>
                        </Fieldset>

                        <Fieldset class="base-fieldset" legend="2.4.1" :toggleable="true" :collapsed="false">
                            <h2> 11.07.2023 </h2>
                            <p> • A new view showing the history of relases has been introduced. News and changes in the application will be described there.</p>
                            <p> • The possibility of tagging scenarios has been added, now by adding a tag to a scenario it is possible to filter scenarios depending on the model running on SimuBox in the cloud. </p>
                            <p> • Small visual fixes </p>
                        </Fieldset>

                        <Fieldset class="base-fieldset" legend="2.4.0" :toggleable="true" :collapsed="false">
                            <h2> 15.06.2023 </h2>
                            <p> • The show grid button has been removed and a toggle button has been added in its place, which allows you to switch between simulation and edit mode</p>
                            <p> • Changed the display of the simulation connection. Footbar now shows connection status, latency, refresh ratio, connected model, login and app version</p>
                        </Fieldset>
                </template>
            </Card>
        </div>
    </div> 
</template>

<script>
import Card from 'primevue/card';
import Fieldset from 'primevue/fieldset';
export default {
    components: {
        Card,
        Fieldset
    }
}
</script>

<style scoped>
.releases-card {
    width: 100%;
}
.releases-container > * {
    margin-top: 1rem;
    color: white;
}

.row-container { 
    display: flex;
    padding: 10px;
}

h2 {
    color: var(--font3) !important;
}

@media (max-width: 1400px) {
    .row-container {
        display: flex;
        flex-direction: column;
        padding: 10px;
    }
}
</style>